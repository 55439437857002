import React from "react";
import "./ProdMailerCustom.scss";

const ValkhanMailerDetails = () => {
  return (
    <section className="container">
      <div className="row mb-4">
        <div className="col">
          <h2>Plataforma de Email Marketing e Validação de Emails</h2>
          <p>
            Crie campanhas de email marketing de forma eficiente e validada.
            Nosso editor interativo online permite que você desenvolva emails
            visualmente atraentes e impactantes. Além disso, nossa ferramenta de
            validação de emails garante que suas mensagens cheguem às caixas de
            entrada corretas, evitando filtros de spam. 
          </p>
        </div>
      </div>


      <div className="row mb-4">
        <div className="col">
          <h3>Criação de Campanhas Personalizadas</h3>
          <p>
            Crie campanhas de email personalizadas que se destacam. Oferecemos recursos que permitem segmentar seu público-alvo
            com precisão. Personalize cada mensagem com conteúdo relevante e
            adaptado aos interesses e comportamentos dos seus destinatários.
            Teste diferentes variações de conteúdo para otimizar taxas de abertura e conversão. Desde emails
            promocionais até newsletters informativas, nossa plataforma facilita
            a criação de campanhas impactantes.
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <h3>Relatórios Detalhados de Desempenho</h3>
          <p>
            Analise o desempenho das suas campanhas, acompanhe taxas de abertura e indicadores-chave para entender melhor o comportamento do seu
            público-alvo. Nossos relatórios detalhados fornecem uma visão
            abrangente do impacto das suas estratégias de email marketing,
            permitindo ajustes em tempo real para maximizar o ROI. Compreenda o
            comportamento do usuário, identifique padrões de engajamento e tome
            decisões informadas para melhorar continuamente suas campanhas de
            marketing.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Vantagens</h3>
          <ul>
            <li>
              <b>Editor Interativo:</b> Crie emails profissionais sem
              necessidade de conhecimento técnico avançado.
            </li>
            <li>
              <b>Validação de Emails:</b> Garanta que suas mensagens alcancem as
              caixas de entrada corretas.
            </li>
            <li>
              <b>Integração Fácil:</b> Conecte-se com seus sistemas CRM e outras
              plataformas de marketing.
            </li>
            <li>
              <b>Relatórios de Engajamento:</b> Avalie o sucesso das suas
              campanhas com métricas detalhadas.
            </li>
            <li>
              <b>Suporte Contínuo:</b> Equipe técnica disponível para ajudar na
              implementação e uso da plataforma.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ValkhanMailerDetails;
