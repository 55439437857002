// Products.js
import React from "react";
import "./Products.scss";
import "../CallToAction/CallToAction.scss";

const Products = () => {
  return (
    <section id="products-cnpj" className="products-cnpj">
      <div className="container" data-aos="zoom-in">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h3>Conheça nossa Base de Dados de CNPJs</h3>
            <p>
              Tenha acesso a <strong>+50 milhões de CNPJs oficiais</strong> da
              Receita Federal, disponíveis em formatos SQL e CSV.
            </p>
            <p>
              Ideal para <strong>captação de leads</strong> e{" "}
              <strong>análises de mercado</strong>. Garanta negócios com
              empresas idôneas e mitigue problemas financeiros.
            </p>
            <p>
              <b>Bônus:</b> Inclui listas de CNAEs, motivos de situação
              cadastral, natureza jurídica, países, municípios IBGE,
              qualificação de sócios, empresas optantes pelo Simples e MEI, e
              QSA | Sócios.
            </p>
            <p>
              <b>Desconto Exclusivo:</b> Obtenha 50% de desconto nas
              atualizações mensais ao comprar a base completa. Atualizações
              enviadas automaticamente por email.
            </p>
            <a href="/products/cnpj-database" className="products-cnpj-btn mb-3">
              Saiba Mais
            </a>
          </div>
          <div className="col-lg-4">
            <div className="card benefits-card">
              <h4>Principais Benefícios</h4>
              <ul>
                <li>+50 Milhões de CNPJs</li>
                <li>Dados Oficiais da Receita Federal</li>
                <li>Formatos SQL e CSV Disponíveis</li>
                <li>Ideal para Captação de Leads</li>
                <li>Atualizações Mensais com 50% Desconto</li>
                <li>Listas Bônus Incluídas</li>
                <li>Entrega Digital Segura</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
