// ProdCnpjFeatures.js

import React from "react";
import "../Services/ServicesExamples.scss"; // Importe o arquivo de estilos Sass para este componente
import examples from "./Examples.json"; // Importe a lista de serviços

const ProdCnpjFeatures = () => {
  return (
    <section id="prodCnpj" className="pricing">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-title">
          <h2>CONHEÇA OS BENEFÍCIOS</h2>
          <p>
            Para te auxiliar na compreensão do valor de manter dentro de casa sua própria base de dados de CNPJ, considere:
          </p>
        </div>
        <div className="listas">
          {examples.map((example, index) => (
            <div
              className="listaItem text-center justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
              key={index}>
              <div className={"box featured item"+ (index+1)}>
                <h3>{example.category}</h3>
                <ul>
                  {example.items.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      <i>&#10003;</i> {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProdCnpjFeatures;
