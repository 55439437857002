import React from "react";
import logoVt from "../../img/logos/logoVT256.png";
import "../Privacy/Privacy.scss";

const Terms = () => {
  return (
    <div>
      <div className="logoValkhanTech">
        <div>
          <img className="logo" src={logoVt} alt="Logotipo Valkhan Tech" />
          <br />
          <h2>Valkhan Tech</h2>
        </div>
      </div>
      <h1>Termos de Serviço</h1>
      <p>
        Bem-vindo ao website da Valkhan Tech. Ao acessar ou utilizar nosso site
        e serviços, você concorda em cumprir estes termos de serviço. Leia
        atentamente antes de prosseguir.
      </p>

      <h2>Uso do Site</h2>
      <p>O uso deste site está sujeito aos seguintes termos de uso:</p>
      <ul>
        <li>
          O conteúdo das páginas deste site é apenas para informação geral e
          pode ser alterado sem aviso prévio.
        </li>
        <li>
          Nem nós, nem terceiros, garantimos a precisão, atualidade, desempenho,
          integridade ou adequação das informações e materiais encontrados ou
          oferecidos neste site para qualquer finalidade específica.
        </li>
        <li>
          Você reconhece que tais informações e materiais podem conter
          imprecisões ou erros, e não nos responsabilizamos por qualquer
          imprecisão ou erro até o limite máximo permitido por lei.
        </li>
      </ul>

      <h2>Serviços Oferecidos</h2>
      <p>
        Nossos serviços incluem consultoria em TI, desenvolvimento de sistemas e
        aplicativos, integrações e suporte tecnológico. Ao utilizar nossos
        serviços, você concorda em cumprir nossos termos de serviço e políticas
        de privacidade.
      </p>

      <h2>Limitação de Responsabilidade</h2>
      <p>
        Não seremos responsáveis por quaisquer danos diretos, indiretos,
        especiais, consequenciais ou incidentais decorrentes do uso ou
        incapacidade de usar este site ou nossos serviços.
      </p>

      <h2>Alterações nos Termos</h2>
      <p>
        Reservamo-nos o direito de modificar estes termos de serviço a qualquer
        momento. Recomendamos que você revise periodicamente esta página para
        estar ciente de eventuais atualizações.
      </p>

      <h2>Contato</h2>
      <p>
        Se tiver dúvidas sobre estes termos de serviço, entre em contato conosco
        pelo e-mail: <a href="mailto:suporte@valkhan.com.br">suporte@valkhan.com.br</a>
      </p>
    </div>
  );
};

export default Terms;
