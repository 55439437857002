import React from "react";
import logoVt from "../../img/logos/logoVT256.png";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div>
      <div className="logoValkhanTech">
        <div>
          <img className="logo" src={logoVt} alt="Logotipo Valkhan Tech" />
          <br />
          <h2>Valkhan Tech</h2>
        </div>
      </div>
      <h1>Política de Privacidade</h1>
      <p>
        Na <strong>Valkhan Tech</strong>, comprometemo-nos integralmente com a privacidade dos
        nossos clientes. Com valores sólidos de excelência e integridade,
        priorizamos a transparência e a inovação em todas as soluções digitais
        personalizadas que desenvolvemos. Nossa missão é ajudar os clientes a
        atingirem seu potencial máximo, mantendo sempre o respeito e a segurança
        de seus dados.
      </p>

      <h2>Informações Coletadas</h2>
      <p>
        Quando você utiliza nossos serviços, podemos coletar informações
        pessoais como nome, endereço de e-mail, número de telefone, informações
        de pagamento, entre outros dados relevantes para a prestação de nossos
        serviços.
      </p>

      <h2>Uso das Informações</h2>
      <p>
        As informações coletadas são utilizadas para fornecer os serviços
        contratados, personalizar sua experiência, melhorar nossos produtos,
        enviar comunicações relevantes, processar pagamentos e cumprir
        obrigações legais.
      </p>

      <h2>Proteção dos Dados</h2>
      <p>
        Nós adotamos medidas de segurança para proteger suas informações contra
        acesso não autorizado, uso indevido, alteração ou divulgação não
        autorizada. Utilizamos criptografia, firewalls, controles de acesso e
        outras práticas recomendadas para garantir a segurança dos dados.
      </p>

      <h2>Compartilhamento de Informações</h2>
      <p>
        Podemos compartilhar suas informações com terceiros apenas quando
        necessário para a prestação dos serviços contratados, cumprimento de
        obrigações legais ou com seu consentimento prévio. Não vendemos ou
        alugamos suas informações para terceiros.
      </p>

      <h2>Cookies e Tecnologias Semelhantes</h2>
      <p>
        Nosso site utiliza cookies e tecnologias semelhantes para melhorar sua
        experiência de navegação, personalizar conteúdos, analisar o uso do site
        e fornecer publicidade relevante. Você pode configurar seu navegador
        para bloquear cookies ou receber avisos sobre sua utilização.
      </p>

      <h2>Alterações na Política de Privacidade</h2>
      <p>
        Reservamo-nos o direito de modificar esta política de privacidade a
        qualquer momento. Recomendamos que você revise periodicamente esta
        página para estar ciente de eventuais atualizações.
      </p>

      <h2>Contato</h2>
      <p>
        Se tiver dúvidas, sugestões ou preocupações sobre nossa política de
        privacidade, entre em contato conosco pelo e-mail:
        <a href="mailto:suporte@valkhan.com.br">suporte@valkhan.com.br</a>
      </p>
    </div>
  );
};

export default Privacy;
