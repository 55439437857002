// CallToAction.js

import React from "react";
import "./CallToAction.scss"; // Importe o arquivo de estilos Sass para este componente

const CallToAction = () => {
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="row">
          <div className="col-lg-9 text-center text-lg-start">
            <h3>Chegou o momento de fazer acontecer!</h3>
            <p>
              Com a Valkhan Tech, você pode 
              <strong> tirar suas ideias do papel e levá-las para a nuvem</strong>, onde serão
              transformadas em propulsores do crescimento do seu negócio.
            </p>
            <p>
                <b>Não está convencido ainda?</b> Confira exemplos dos serviços que oferecemos, nossos parceiros comerciais e alguns cases de sucesso que ilustram a qualidade e eficácia das nossas soluções
            </p>
          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn align-middle" href="#team">
              Fale conosco
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
