// ServicesExamples.js

import React from "react";
import "./ServicesExamples.scss"; // Importe o arquivo de estilos Sass para este componente
import examples from "./Examples.json"; // Importe a lista de serviços

const ServicesExamples = () => {
  return (
    <section id="pricing" className="pricing">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-title">
          <h2>PARCEIROS E APLICAÇÕES</h2>
          <p>
            Para te auxiliar no entendimento de como a Valkhan Tech pode te ajudar, veja alguns exemplos de serviços que oferecemos para se inspirar.
          </p>
        </div>
        <div className="listas">
          {examples.map((example, index) => (
            <div
              className="listaItem text-center justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
              key={index}>
              <div className={"box featured item"+ (index+1)}>
                <h3>{example.category}</h3>
                <ul>
                  {example.items.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      <i>&#10003;</i> {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesExamples;
