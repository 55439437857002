import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import Contact from "../components/Contact/Contact";
import { Helmet } from "react-helmet";
import ProdCnpjFeatures from "../components/ProdCnpjFeatures/ProdCnpjFeatures";
import prodCnpjImg1 from "../img/products/cnpj-prod-1.webp";
import ProductCards from "../components/ProductCards/ProductCards";
import ProdCnpjDetails from "../components/ProdCnpjFeatures/ProdCnpjDetails";
import ProdCnpjCustom from "../components/ProdCnpjFeatures/ProdCnpjCustom";

const LPBaseCnpj = () => {
  return (
    <>
      <Helmet>
        <title>Lista de Empresas +57 milhões de CNPJs</title>
        <meta
          name="description"
          content="Tenha sua própria base de dados com +57 milhões de CNPJs oficiais da Receita Federal. Ideal para captação de leads, análises de mercado e validação cadastral."
        />
        <meta
          property="og:url"
          content="https://valkhan.com.br/products/cnpj-database"
        />
        <meta
          property="og:title"
          content="Lista de empresas - +57 Milhões de CNPJs Oficiais"
        />
        <meta
          property="og:description"
          content="Tenha sua própria base de dados com +57 milhões de CNPJs oficiais da Receita Federal. Ideal para captação de leads, análises de mercado e validação cadastral."
        />
        <meta
          property="og:image"
          content={"https://valkhan.com.br" + prodCnpjImg1}
        />
      </Helmet>
      <HeroSection
        title="+57 milhões de CNPJs"
        subtitle="Ideal para captação de leads, análises de mercado e validação cadastral. Garanta negócios seguros e otimize suas estratégias de vendas!"
        imageUrl={prodCnpjImg1}
      />
      <div id="main">
        <Header />
        <ProdCnpjDetails />
        <ProdCnpjFeatures />
        <ProductCards />
        <ProdCnpjCustom />
        <Contact />
      </div>
    </>
  );
};

export default LPBaseCnpj;
