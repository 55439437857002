import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import heroImg from "../img/hero-img.webp";
import AboutUs from "../components/AboutUs/AboutUs";
import WhyUs from "../components/WhyUs/WhyUs";
import Services from "../components/Services/Services";
import CallToAction from "../components/CallToAction/CallToAction";
import ServicesExamples from "../components/Services/ServicesExamples";
import Contact from "../components/Contact/Contact";
import ProductsCta from "../components/Products/Products";
import { Helmet } from "react-helmet";

const Home = () => {
    return (
      <>
        <Helmet>
          <title>Valkhan Tech</title>
          <meta name="description" content="Conheça a Valkhan Tech - Desenvolvimento de soluções em tecnologia da informação personalizada ao seu negócio." />
          <meta property="og:title" content="Valkhan Tech" />
          <meta property="og:description" content="Conheça a Valkhan Tech - Desenvolvimento de soluções em tecnologia da informação personalizada ao seu negócio." />
        </Helmet>
        <HeroSection
          title="Tecnologia e Inovação"
          subtitle="A Valkhan Tech desenvolve produtos e soluções digitais sob medida para o seu negócio. Atendimento humano e personalizado."
          imageUrl={heroImg}
        />
        <div id="main">
          <Header />
          <AboutUs />
          <WhyUs />
          <Services />
          <CallToAction />
          <ServicesExamples />
          <ProductsCta />
          <Contact />
        </div>
      </>
    );
  };

  export default Home;