import React from "react";
import "./css/global.scss";
import "./css/animate.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Terms/Terms";
import PageHome from "./pages/Home";
import LPBaseCnpj from "./pages/LpBaseCNPJ";
import LPMailer from "./pages/LpMailer";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/products/cnpj-database" element={<LPBaseCnpj />} />
          <Route path="/products/valkhan-mailer" element={<LPMailer />} />
        </Routes>
      </div>
    </Router>
  );
}



export default App;
