// ProdCnpjCustom.js
import React from "react";
import "./ProdCnpjCustom.scss"; // Importe o arquivo de estilos Sass para este componente

const ProdCnpjCustom = () => {
  return (
    <section id="custom-solution" className="custom-solution">
      <div className="container" data-aos="zoom-in">
        <div className="section-title">
          <h2>Soluções personalizadas</h2>
        </div>
        <p>
          Se você procura uma solução personalizada, nossa equipe está pronta
          para atender suas necessidades específicas. Oferecemos diversos
          serviços adicionais:
        </p>
        <div className="row">
          <div className="col-lg-6">
            <ul>
              <li>API para Consulta em Tempo Real</li>
              <li>Customização de Base de Dados</li>
              <li>Extração de Leads Mais Qualificados</li>
              <li>Adição de Latitude e Longitude</li>
              <li>Padronização de Endereços</li>
              <li>Identificação de Domínios e Sites</li>
              <li>Enriquecimento de Base de Dados</li>
            </ul>
          </div>
          <div className="col-lg-6">
            <p>
              <strong>O que é Enriquecimento de Base de Dados?</strong>
              <br /> <br />É um conceito de tratamento de informações que consiste em adicionar, padronizar, tratar e validar
              informações adicionais aos seus registros existentes. Isso pode
              incluir dados como localização geográfica (latitude e longitude),
              padronização e correção de endereços, informações de contato, e
              muito mais. O objetivo é tornar seus dados mais completos e úteis
              para análises e tomadas de decisão.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProdCnpjCustom;
