import React, { useState, useEffect } from "react";
import "./Header.scss";
import logo from "../../img/logos/logoVlkT.svg";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Remove o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="header"
      className={`fixed-top ${scrolled ? "header-scrolled" : ""}`}>
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <a className="linkTitle" href="/">
            <img src={logo} alt="Valkhan tech logo" className="logoImg"></img>
            <span>Valkhan Tech</span>
          </a>
        </h1>
      </div>
    </header>
  );
};

export default Header;
