// Services.js

import React from "react";
import "./Services.scss"; // Importe o arquivo de estilos Sass para este componente
import servicesList from "./ServiceList.json"; // Importe a lista de serviços

const Services = () => {

  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Serviços</h2>
        </div>

        <div className="row">
          {servicesList.map((service, index) => (
            <div
              className="col-xl-4 col-md-6 d-flex align-items-stretch mb-4"
              data-aos="zoom-in"
              data-aos-delay={100 + index * 100} // Delay aumenta conforme o índice
              key={index} // Importante adicionar uma chave única
            >
              <div className="icon-box">
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
