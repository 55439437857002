// Contact.js

import React from "react";
import "./Contact.scss"; // Importe o arquivo de estilos Sass para este componente
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import fotoPaulo from "../../img/team/paulo.jpg";
import logoVT from "../../img/logos/logoVT256.png";

const Contact = () => {
  return (
    <>
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>FALE CONOSCO</h2>
            <p>
              Pronto para transformar suas ideias em realidade? Entre em contato
              conosco e descubra como podemos ajudar a impulsionar o seu sucesso
              digital!
            </p>
          </div>

          <div className="row">
            <div
              className="col-lg-6 mb-4"
              data-aos="zoom-in"
              data-aos-delay="100">
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src={fotoPaulo} alt="CEO Paulo Vitor de Lima" width="180px" height="180px" />
                </div>
                <div className="member-info">
                  <h4>Paulo Lima</h4>
                  <span>Chief Executive Officer</span>
                  <p>Fundador da Valkhan Tech</p>
                  <div className="social">
                    <a
                      href="https://www.linkedin.com/in/paulo-lima-vlk/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </i>
                    </a>
                    <a
                      href="https://wa.me/+5511965572558?text=Ol%C3%A1%2C%20Paulo%21%20Estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20da%20Valkhan%20Tech."
                      target="_blank"
                      rel="noopener noreferrer">
                      <i>
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </i>
                    </a>
                    <a href="mailto:vendas@valkhan.com.br">
                      <i>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4" data-aos="zoom-in" data-aos-delay="100">
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src={logoVT} alt="Logotipo Valkhan Tech" width="180px" height="180px" />
                </div>
                <div className="member-info">
                  <h4>Valkhan Tech</h4>
                  <span>Suporte | Relacionamento</span>
                  <p>Canais oficiais Valkhan Tech</p>
                  <div className="social">
                    <a href="mailto:suporte@valkhan.com.br">
                      <i>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid footer-bottom clearfix">
        <div className="copyright">
          Valkhan Tech<sup>&copy;</sup> 2024
          <br />
          Todos os direitos reservados <a href="https://valkhan.com.br/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
        </div>
      </div>
    </>
  );
};

export default Contact;
