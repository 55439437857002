// HeroSection.js

import React from 'react';
import './HeroSection.scss'; // Importe o arquivo de estilos Sass para este componente

const HeroSection = ({ title, subtitle, imageUrl }) => {
    return (
        <section id="hero" className="d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                        <h1>{title}</h1>
                        <h2>{subtitle}</h2>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                        <img src={imageUrl} className="img-fluid animated" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroSection;
