import React from "react";
import ProdThumb from "../../img/products/cnpj-prod-2.webp";
import "./ProductCards.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

const ProductCards = () => {
  return (
    <section id="cnpjProductCards" className="cnpjProductCards">
      <div className="container">
        <h3 className="text-center text-white mb-4">
          Compra segura através do Mercado Shops (Mercado Livre)
        </h3>
        <div className="row cardsContainer">
          <div className="col-md-4">
            <div className="card mb-4">
              <div className="imgContainer">
                <img
                  src={ProdThumb}
                  className="card-img-top"
                  alt="Base completa"
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">Base Completa - 1ª Compra</h5>
                <p className="card-text">
                  Obtenha acesso à base de dados completa com +57 milhões de
                  CNPJs atualizada no mês vigente para download e utilização em
                  seu próprio ambiente através de download seguro.
                </p>

                <div className="buyAction">
                  <a
                    href="https://valkhantech.mercadoshops.com.br/MLB-3754149629-lista-cnpj-receita-federal-atualizada-mensal-em-sql-ou-csv-_JM"
                      target="_blank"
                    className="btn-buy">
                    Comprar
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="imgContainer">
                <img
                  src={ProdThumb}
                  className="card-img-top"
                  alt="Base Completa - Atualização"
                />
                <span className="imgOverlay">
                  -50% OFF
                </span>
              </div>
              <div className="card-body">
                <h5 className="card-title">Base Completa - Atualização</h5>
                <p className="card-text">
                  Entendemos a importância de sempre estar atualizados, e por isso oferecemos um desconto especial para quem já adquiriu a base completa na atualização mensal.
                </p>
                <div className="buyAction">
                  <a
                    href="https://valkhantech.mercadoshops.com.br/MLB-4818466290-lista-cnpj-receita-federal-pacote-de-atualizaco-_JM"
                    target="_blank"
                    className="btn-buy">
                    Comprar
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
        <p className="text-white text-center mt-5 mb-0">Compras diretas no PIX contam com 10% de desconto adicional. Entre em contato.</p>
      </div>
    </section>
  );
};

export default ProductCards;
