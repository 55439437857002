// AboutUs.js

import React from "react";
import "./AboutUs.scss"; // Importe o arquivo de estilos Sass para este componente

const AboutUs = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Sobre nós</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p>
            Na Valkhan Tech, transparecemos a nossa essência através da excelência no relacionamento com o cliente.
            </p>
            <ul>
              <li>
                <i className="ri-check-double-line"></i> <strong>Princípios:</strong> Foco no cliente, transparência e inovação
              </li>
              <li>
                <i className="ri-check-double-line"></i> <strong>Valores:</strong> Excelência, Integridade e Colaboração
              </li>
              <li>
                <i className="ri-check-double-line"></i> Nosso propósito é <strong>capacitar empresas e indivíduos</strong> a alcançarem seu <strong>potencial máximo</strong> por meio de soluções digitais eficientes e impactantes.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
            Com uma equipe de profissionais e parceiros com mais de uma década de experiência, estamos comprometidos em não apenas atender, mas exceder suas expectativas. Desde o primeiro contato até a entrega do produto final, você pode confiar na Valkhan Tech para te auxiliar à chegar nos seus objetivos com segurança e tranquilidade.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
