// WhyUs.js

import React from "react";
import "./WhyUs.scss"; // Importe o arquivo de estilos Sass para este componente

const WhyUs = () => {
  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
            <div className="content">
              <h3>
                Como podemos te ajudar <strong>hoje?</strong>
              </h3>
              <p>
                Assim como um alfaiate que personaliza cada peça conforme o
                gosto e as medidas do cliente.
                <br />
                Oferecemos soluções sob medida, sem distinção entre projetos
                grandes ou pequenos, priorizando sempre as necessidades e
                desejos do cliente.
              </p>
            </div>

            <div className="accordion-list">
              <ul>
                <li>
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-1"
                    aria-expanded="true"
                    className="collapse"
                    >
                    <span>01</span> Quais são os principais desafios que podem
                    surgir durante o desenvolvimento de uma solução customizada?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </button>
                  <div
                    id="accordion-list-1"
                    className="collapse show"
                    data-bs-parent=".accordion-list">
                    <p>
                      A clareza na definição do projeto técnico é
                      a chave para o sucesso de qualquer projeto. Avaliamos
                      cuidadosamente as necessidades específicas do seu projeto
                      para definir a melhor abordagem técnica e metodológica.
                      Utilizamos das melhores práticas de desenvolvimento de
                      software, garantindo a qualidade e a eficiência do produto
                      final.
                    </p>
                  </div>
                </li>

                <li>
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-2"
                    className="collapsed">
                    <span>02</span> Quais são as vantagens de adotar uma
                    abordagem de desenvolvimento ágil?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </button>
                  <div
                    id="accordion-list-2"
                    className="collapse"
                    data-bs-parent=".accordion-list">
                    <p>
                      O desenvolvimento ágil oferece maior flexibilidade,
                      transparência e capacidade de adaptação aos requisitos,
                      permitindo entregas mais rápidas e incrementais com maior
                      valor agregado.
                    </p>
                  </div>
                </li>

                <li>
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-3"
                    className="collapsed">
                    <span>03</span> Como a Valkhan Tech pode auxiliar com
                    o crescimento do nosso negócio?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </button>
                  <div
                    id="accordion-list-3"
                    className="collapse"
                    data-bs-parent=".accordion-list">
                    <p>
                      Nosso relacionamento com os clientes permite e incentiva o
                      entendimento de seus processos internos e necessidades,
                      para que possamos oferecer soluções que sejam
                      verdadeiramente eficazes e escaláveis.
                    </p>
                  </div>
                </li>

                <li>
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-4"
                    className="collapsed">
                    <span>04</span> Quero desenvolver uma solução digital, quais são os próximos passos? <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </button>
                  <div
                    id="accordion-list-4"
                    className="collapse"
                    data-bs-parent=".accordion-list">
                    <p>
                      Assim como para construir uma casa é necessário um <u>engenheiro e um arquiteto</u>, para desenvolver uma solução digital é necessário um <i>planejamento por uma equipe qualificada</i>.<br/>
                      Entre em contato conosco para agendar uma reunião
                      inicial, onde poderemos discutir suas ideias e
                      necessidades e elaboraremos um <strong>plano de
                      ação personalizado!</strong>
                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </div>

          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2 img animated pulse slower infinite"
            data-aos="zoom-in"
            data-aos-delay="150">
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
