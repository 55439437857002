

const ProdCnpjDetails = () => {
    return (
        <section className="container">
          <div className="row mb-4">
            <div className="col">
              <h2>Base de dados CNPJ oficial Receita Federal</h2>
              <p>+57 Milhões de empresas e estabelecimentos</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <h3>Captação de Leads e Análise de Mercado</h3>
              <p>
                Você está buscando uma solução eficaz para impulsionar suas
                estratégias de marketing e vendas? Com informações atualizadas e
                detalhadas de empresas de todo o Brasil, você terá acesso a uma
                vasta rede de potenciais clientes. Identifique leads
                qualificados e direcione suas campanhas de marketing com
                precisão.
              </p>
              <p>
                Realize análises aprofundadas do mercado com dados confiáveis e
                abrangentes. Nossa base de dados inclui listas de CNAEs, motivos
                de situação cadastral, natureza jurídica, países, municípios
                IBGE, qualificação de sócios, e empresas optantes pelo Simples e
                MEI. Tudo isso para você entender melhor o cenário e tomar
                decisões estratégicas informadas.
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <h3>Análise de risco e validação de situação cadastral</h3>
              <p>
                Garanta negócios com empresas idôneas, mitigue problemas
                financeiros e capte empresas melhor qualificadas com informações
                oficiais da Receita Federal!
              </p>
              <p>
                A base de dados oferece informações relevantes para validar e
                consultar o relacionamento da empresa com a receita indicando os
                motivos de alertas para empresas passando por situações de
                alerta e possibilitando filtro de empresas idôneas.
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <h3>Vantagens</h3>
              <ul>
                <li>
                  <b>Atualizações Mensais:</b> Dados sempre atualizados para garantir a
                  relevância das suas estratégias.
                </li>
                <li>
                  <b>Formatos Diversos:</b> Disponível em SQL e CSV, compatível com
                  MySQL, MariaDB, SQLite e mais.
                </li>
                <li>
                  <b>Entrega Digital Segura:</b> Receba sua base de dados por email com
                  link seguro, disponível para download por 30 dias.
                </li>
                <li>
                  <b>Integração aos seus sistemas:</b> A base de dados é sua,
                  disponível 24h em formato offline, não dependa de terceiros,
                  integre diretamente à sua base de dados existente.
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h3>Plano de fidelidade</h3>
              <p>
                Ao comprar a base completa, você pode optar pela compra com 50%
                de desconto nas atualizações mensais.
              </p>
              <p>
                Mantenha seus dados sempre atualizados com as últimas
                informações.
              </p>
              <p>
                Receba links para download automaticamente por email a cada nova
                atualização, garantindo que você sempre tenha acesso aos dados
                mais recentes.
              </p>
            </div>
          </div>
        </section>
    )
}

export default ProdCnpjDetails