import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import Contact from "../components/Contact/Contact";
import { Helmet } from "react-helmet";
import ProdMailerFeatures from "../components/ProdMailerFeatures/ProdMailerFeatures";
import prodCnpjImg1 from "../img/products/mailer-02.webp";
import ProdMailerDetails from "../components/ProdMailerFeatures/ProdMailerDetails";

const LPBaseCnpj = () => {
  return (
    <>
      <Helmet>
        <title>Valkhan Mailer: Transformando Emails em Conexões Poderosas</title>
        <meta
          name="description"
          content="Transforme campanhas em sucesso com nosso editor de email marketing interativo."
        />
        <meta
          property="og:url"
          content="https://valkhan.com.br/products/valkhan-mailer"
        />
        <meta
          property="og:title"
          content="Valkhan Mailer: Transformando Emails em Conexões Poderosas"
        />
        <meta
          property="og:description"
          content="Simplifique suas campanhas de email com nossa plataforma integrada e poderosa."
        />
        <meta
          property="og:image"
          content={"https://valkhan.com.br" + prodCnpjImg1}
        />
      </Helmet>
      <HeroSection
        title="Valkhan Mailer"
        subtitle="Conheça nossa ferramenta de email Marketing com editor interativo e acompanhe o sucesso de suas campanhas."
        imageUrl={prodCnpjImg1}
      />
      <div id="main">
        <Header />
        <ProdMailerDetails />
        <ProdMailerFeatures />
        <Contact />
      </div>
    </>
  );
};

export default LPBaseCnpj;
